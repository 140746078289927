import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/headers/productionAssetTracking';
import IconList from '../components/iconList';
import HowItWorks from '../components/howItWorks';
import ContactForm from '../components/contactForm';

import ManualTracking from '../assets/svg/manual-tracking.svg';
import OptimizeFlow from '../assets/svg/optimize-flow.svg';
import Efficiency from '../assets/svg/efficiency.svg';
import EasyToInstall from '../assets/svg/easy-to-install.svg';

import { colors, media } from '../styles/constants';
import layoutStyle from '../styles/layout';
import Logos from '../components/logos';

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  ${layoutStyle}

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  hr {
    border: solid 1px ${colors.blue};
    border-radius: 5px;
    opacity: 0.5;
    max-width: 40rem;
    width: 100%;
    margin: 2rem 0;

    @media (min-width: ${media.tabletPortrait}px) {
      display: none;
    }
  }

  > div:first-child {
    flex: 1 0 100%;
    width: 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      margin-right: 4rem;
      flex: 1 0 65%;
    }
  }

  > div:last-child {
    flex: 1 0 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      display: flex;
      flex-direction: column;
      flex: 0 1 45%;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h3 {
      line-height: 1.2;
      color: ${colors.blue};
      font-size: 1.5rem;
      padding-top: 0.5rem;
    }

    ul {
      padding-top: 0.5rem;
      list-style: none;

      svg {
        width: 1rem;
        margin-right: 0.5rem;
        margin-bottom: -0.25rem;
      }

      li {
        padding-bottom: 0.25rem;
      }
    }

    span {
      font-size: 1.25rem;
    }
  }
`;

function MilkRunPage({ location: { pathname } }) {
  const data = useStaticQuery(graphql`
    query {
      beacon: file(relativePath: { eq: "pat-new-tag.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tracker: file(relativePath: { eq: "pat-gateway.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      dashboard: file(relativePath: { eq: "dashboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Tugger Trains" pathname={pathname} />
      <Layout Header={<Header />}>
        <IconList
          title="Our Solution Enables You To"
          list={[
            {
              Icon: ManualTracking,
              title: 'Eliminate Manual Tracking',
              text: 'Automate asset location tracking, reducing errors'
            },
            {
              Icon: OptimizeFlow,
              title: 'Optimize Production Flow',
              text:
                'Monitor equipment availability and eliminate searching time'
            },
            {
              Icon: Efficiency,
              title: 'Increase Operational Efficiency',
              text: 'Leverage data analytics for better decision-making'
            },
            {
              Icon: EasyToInstall,
              title: 'Cost-Effective & Simple Installation',
              text: 'Minimal infrastructure required, easy to scale'
            }
          ]}
        />
        <HowItWorks
          ivt={false}
          list={[
            {
              title: 'Precision Tracking with Affordable Active IoT Tags',
              bullets: [
                'IoT Tags attach to production assets, materials, or vehicles',
                '6-year battery life minimizes maintenance efforts',
                'Works effectively even with assets made of metal'
              ],
              image: data.beacon.childImageSharp.fluid
            },
            {
              title: 'Simple Infrastructure with High Accuracy Gateways',
              bullets: [
                'Uses forklift movements to locate and track assets in real time dynamically',
                'Quick installation with minimal infrastructure requirements, reducing deployment complexity',
                'Cost-effective scalability, allowing expansion across warehouses and manufacturing facilities'
              ],
              image: data.tracker.childImageSharp.fluid
            },
            {
              title: 'Software and Data Integration',
              bullets: [
                'Online Custom Dashboard',
                'Real-time asset status, movement history, and analytics',
                'API integration allows seamless connectivity with MES and WMS systems'
              ],
              image: data.dashboard.childImageSharp.fluid
            }
          ]}
        />
        <Logos />
        <FormContainer>
          <ContactForm
            title="Interested in a demo? Leave us a message"
            page="Tugger Train Tracking"
          />
          <hr />
        </FormContainer>
      </Layout>
    </>
  );
}

MilkRunPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default MilkRunPage;
